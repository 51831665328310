/**
 * Created by dpardell on 16/06/2018.
 */
angular
    .module('annexaApp')
    .component('annexaAuditBase', {
        templateUrl: './components/admin/annexa-audit-base/annexa-audit-base.html',
        controller: ['Language', '$filter', 'GlobalDataFactory', '$state',function (Language, $filter, GlobalDataFactory, $state) {
            var vm = this;

            vm.state = $state.current.name;
            vm.addToFilter = {filter:[], filterAux:[]};

            var actions = $linq(GlobalDataFactory.auditActions).where(function(x){
                var contains = false;
                if(vm.auditType){
                    if(x.types){
                        _.forEach(x.types, function (type) {
                            if(type == vm.auditType){
                                contains = true;
                            }
                        });
                    }
                }else{
                    contains = true;
                }
                return contains;
            }).toArray();

            var actionRenderAction = function(data, type, object, meta) {
                var act = $linq(GlobalDataFactory.auditActions).where("x => x.id == '"+data+"'").toArray();
                if(act && act.length > 0){
                    return $filter('translate')(act[0][Language.getActiveColumn()]);
                }
                return "";
            };
            var actionRenderObject = function(data, type, object, meta) {
                var obj = $linq(GlobalDataFactory.auditTypes).where("x => x.id == '"+data+"'").toArray();
                if(obj && obj.length > 0){
                    return $filter('translate')(obj[0][Language.getActiveColumn()]);
                }
                return "";
            };

            vm.filter = [];
            vm.filter.push({ id: 'actionDate', type: 'dateRange', order: 0, label: 'global.literals.creation_date', callAux: true });
            if(!vm.auditType){
                vm.filter.push({ id: 'objectType', type: 'select', order: 4, label: 'global.literals.objectType', dataType: 'LOCAL', data: GlobalDataFactory.auditTypes, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true});
            }else{
                vm.addToFilter.filter.push({"name":"objectType", "value":vm.auditType});
            }
            vm.filter.push({ id: 'action', type: 'select', order: 1, label: 'global.literals.action', dataType: 'LOCAL', data: actions, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true});
            if(!vm.objectId){
                vm.filter.push({ id: 'objectId', type: 'text', order: 2, label: 'global.literals.objectId'});
            }else{
                vm.addToFilter.filter.push({"name":"objectId", "value":vm.objectId});
            }
            vm.filter.push({ id: 'userAction', type: 'text', order: 3, label: 'global.literals.user', callAux: true });


            vm.additionalColumns = [];
            vm.additionalColumns.push({ id: 'actionDate', column: new DateTimeColumn($filter, 'global.literals.creation_date') });
            if(!vm.auditType){
                vm.additionalColumns.push({ id: 'objectType', title: $filter('translate')('global.literals.objectType'), render: actionRenderObject});
            }
            vm.additionalColumns.push({ id: 'action', title:$filter('translate')('global.literals.action'), render:actionRenderAction});
            if(!vm.objectId || vm.auditType == 'DILIGENCE'){
                vm.additionalColumns.push({ id: 'objectId', column: new DatabaseTranslatedColumn($filter, 'global.literals.objectId','objectId')  });
            }
            vm.additionalColumns.push({ id: 'userAction', column: new UserColumn($filter, 'global.literals.user')});
            vm.additionalColumns.push({ id: 'id', visible: false, title:'NotShow' });
        }],
        bindings: {
            auditType: '@?',
            objectId: '@?'
        }
    });