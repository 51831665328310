angular
    .module('annexaApp')
    .component('annexaTaskTypeStatuses', {
        templateUrl: './components/admin/annexa-task-type-statuses/annexa-task-type-statuses.html',
        controller: ['$rootScope', 'Language', '$filter', 'RestService', 'GlobalDataFactory', 'CommonAdminModals', 'AnnexaModalFactory', function($rootScope, Language, $filter, RestService, GlobalDataFactory, CommonAdminModals, AnnexaModalFactory) {
            var vm = this;

            vm.languageColumn = Language.getActiveColumn();
            vm.requiredText = $filter('translate')('global.validation.required');
            vm.inserted = undefined;

            vm.globalStatuses = [
                { id: 'PENDING', name: 'global.literals.PENDING' },
                { id: 'IN_PROGRESS', name: 'global.literals.IN_PROGRESS' },
                { id: 'COMPLETED', name: 'global.literals.COMPLETED' },
                { id: 'CANCELLED', name: 'global.literals.CANCELLED' }
            ];                        

            vm.updateStatus = function (rowformtaskstatus) {
                _.forEach(vm.type.statuses, function(value, key) {
                    var languageNull = true;

                    _.forEach($rootScope.app.languagedef, function(val) {
                        if(value[val.column]) {
                            languageNull = false;
                        }
                    });

                    if(languageNull) {
                        vm.type.statuses.splice(key, 1);
                    }
                });
            }

            vm.removeStatus = function(index) {
                if(!vm.isNew) {
                	var statusToRemove = vm.type.statuses[index];
                    RestService.delete('./api/task_type_status/' + vm.type.statuses[index].id)
                        .then(function(data) {
                            vm.type.statuses.splice(index, 1);
                        	$rootScope.$broadcast('AnnexaTaskTypeStatusDeleted', { item: statusToRemove });
                        }).catch(function(error) {
                            //Empty
                    });
                } else {
                    vm.type.statuses.splice(index, 1);
                }
            }

            vm.addStatus = function() {
                vm.inserted = {
                    language1: undefined,
                    language2: undefined,
                    language3: undefined,
                    acronym: undefined,
                    user: undefined,
                    profile: undefined,	
                    defaultValue: false,
                    sendMail: false,
                    orderView: 0
                }

                if(!vm.isNew) {
                    vm.inserted.type = vm.type;
                }

                vm.type.statuses.push(vm.inserted);
            }

            vm.editStatus = function (index) {
                //Empty
            }

            vm.checkName = function ($data, status, object) {
                var languageOk = true;

                _.forEach($rootScope.app.languagedef, function(val) {
                    if(!$data[val.column]) {
                        languageOk = false;
                    }
                });

	        	if(status.orderView == 0 && vm.type.statuses && vm.type.statuses.length > 0){
	        		status.orderView = $linq(vm.type.statuses).select("x=>x.orderView").max()+10;
	        	}

                if(!languageOk) {
                    return vm.requiredText;
                } else {
                    status = $data;

                    if(vm.isNew) {
                        $rootScope.$broadcast('AnnexaTaskTypeStatusAdded', { statuses: vm.type.statuses });
                    }
                }
            }

            vm.checkAcronym = function ($data, status, object) {
            	if (!$data) {
            		return vm.requiredText;
            	} else {
                    status.acronym = $data;

                    if(vm.isNew) {
                        $rootScope.$broadcast('AnnexaTaskTypeStatusAdded', { statuses: vm.type.statuses });
                    }
            	}
            }
                                    
            vm.selectUserProfile = function(status) {
            	var customFieldsType = [];
            	var modal = angular.copy(CommonAdminModals.taskTypeStatusAssignTo);
                modal.alerts = [];
                modal.data = status;
                modal.languageColumn = Language.getActiveColumn();
                                
                modal.assignToOptions = [{id: 'user', description: $filter('translate')('global.literals.user')}, {id: 'profile', description: $filter('translate')('global.literals.profile')}];
                modal.assignTo;
                if (status.user) {
                	modal.assignTo = 'user';
                } else if (status.profile) {
                	modal.assignTo = 'profile';
                }
                
                if (status.user) {
                	var user_name = '';
                	user_name = status.user.name + ' ' + status.user.surename1 + (status.user.surename2 ? ' ' + status.user.surename2 : '');
                	modal.userAux = {
	                	user: {
	                		id: status.user.id,
	                		user: status.user,
	                		value: user_name
	                	}
                	}
                } else {
                	modal.userAux = {user: undefined};
                }
                
                modal.selectAssignTo = function(value) {
                	modal.assignTo = value;
                }
                
                modal.searchAssignToUser = function (value) {
                    var valueOk = value;
                    if(valueOk && valueOk.val){
                        valueOk = valueOk.val;
                    }
                    if(!valueOk) {
                        return [];
                    }else if(valueOk != '*' && valueOk.length < 3){
                        return [];
                    }else{
                        if(valueOk == '*'){
                            valueOk = '';
                        }
                        return RestService.loadData('common','User',valueOk).then(function(dataSearch) {
                            var usersLoad = [];
                            if(dataSearch.data &&  dataSearch.data.content && dataSearch.data.content.length > 0){
                                angular.forEach(JSOG.decode(dataSearch.data.content), function(val, key) {
                                    var name = val.name+" "+val.surename1;
                                    if(val.surename2){
                                        name = name+" "+val.surename2;
                                    }
                                    usersLoad.push({ 'id': val.id, 'user': val, 'value': name});
                                });
                            }
                            return usersLoad;
                        }).catch(function(){
                            return [];
                        })
                    }
                }
                
                modal.updateAssignTo = function(val, prop){
                	if(prop) {
                		switch (prop) {
                			case 'user':
                				if(val && val.user && val.user.id) {
                					status.user = val.user.user;
                				} else {
                					return $filter('translate')('global.validation.required');
                				}
                				if (status.profile) status.profile = null;
                				break;
                			case 'profile':
                				if(val && val.id) {
                					status.profile = val;
                				} else {
                					return $filter('translate')('global.validation.required');
                				}
                				if (status.user) status.user = null;
                				break;
                		}
                		
                		if (status.id) {
                			$rootScope.$broadcast('AnnexaTaskTypeStatusUpdated', { status: status });
                			
	                		var ttsIndex = $linq(vm.type.statuses).indexOf("x => x.id == " + status.id);
	     					if (ttsIndex != -1) {
	     						vm.type.statuses.splice(ttsIndex, 1, status);
	     					}
                		}
                	}
                }
                
                var selectableOptions = [];
                _.forEach(GlobalDataFactory.allProfiles, function (value, key) {
                    if(value.expiryDate == null || (status.profile && status.profile.id == value.id)) {
                        selectableOptions.push(value);
                    }
                });
                modal.allProfiles = new SortedArray(selectableOptions, modal.languageColumn).sort();
                
                modal.printProfile = function (modelValue) {
                	if (modelValue) {
                		return modelValue[modal.languageColumn];	
                	} else {
                		return '';
                	}
                }
                
                AnnexaModalFactory.showModal('modalTaskTypeStatusAssignTo', modal);
            }
            
            vm.removeUserProfile = function(status) {
            	status.user = null;
            	status.profile = null;	
            	if (status.id) {
            		$rootScope.$broadcast('AnnexaTaskTypeStatusUpdated', { status: status });
            	}
            }
            
            vm.checkDefault = function ($data, status, object) {
                status.defaultValue = $data;

            	if (status.defaultValue) {
                	var defaultStatus = $linq(vm.type.statuses).firstOrDefault(undefined, "x => x.defaultValue == true && x.orderView != " + status.orderView);
                	if (defaultStatus) {
                		defaultStatus.defaultValue = false;
                	}
            	}
            	
                if(vm.isNew) {
                    $rootScope.$broadcast('AnnexaTaskTypeStatusAdded', { statuses: vm.type.statuses });
                }
            }
            
            vm.checkSendMail = function ($data, status, object) {
                status.sendMail = $data;

                if(vm.isNew) {
                    $rootScope.$broadcast('AnnexaTaskTypeStatusAdded', { statuses: vm.type.statuses });
                }
            }

            vm.checkGlobalStatus = function ($data, status, object) {
            	if (!$data) {
            		return vm.requiredText;
            	} else {
                    status.globalStatus = $data;

                    if(vm.isNew) {
                        $rootScope.$broadcast('AnnexaTaskTypeStatusAdded', { statuses: vm.type.statuses });
                    } else {
                        $rootScope.$broadcast('AnnexaTaskTypeStatusUpdated', { status: status });
                    }
            	}
            }

            vm.printYesNo = function (value) {
                if(value == true) {
                    return '<i class="fa fa-check text-success"></i>';
                } else {
                    return '<i class="fa fa-remove text-danger"></i>';
                }
            }
        }],
        bindings: {
            type: '=?',
            isNew: '='
        }
    })