angular
    .module('annexaApp')
    .component('annexaTableProfileUsers', {
        templateUrl: './components/admin/annexa-table-profile-users/annexa-table-profile-users.html',
        controller: ['$rootScope', 'Language', '$filter', 'CommonService', function($rootScope, Language, $filter, CommonService) {
            var vm = this;

            vm.definition.addColumns([
                new editableTableColumnDefaultProfile(
                        'user',
                        'global.literals.user',
                        '30%',
                        function (user) {
                            return user.user.completeName;
                        })
                        .setLoadUserColumn(undefined,
                        	function (user) {
                            	if(user) {
                            		return user.value;
                            	} else {
                            		return "";
                            	}
                        	},
                        	CommonService.loadUsers,
                        	function ($data, column, item) {
                                if(column.required && !$data) {
                                	return $filter('translate')('global.validation.required');
                                } else {
                                	item.user = $data.user;
                                }
                        	},
                        	true
                        ),
                    new editableTableColumnDefaultProfile(
                        'roles',
                        'global.literals.rols',
                        '62%',
                        function (roles) {
                            var result = '';

                            angular.forEach(roles.roles, function (role) {
                                if(result != '') {
                                    result += ', ';
                                }
                                result += role[Language.getActiveColumn()];
                            });

                            return result;
                        })
                        .setSelectColumn(
                            Language.getActiveColumn(),
                            new SortedArray(vm.roles, Language.getActiveColumn()).sort(),
                            function($data, profile, object) {
                                profile.roles = [];

                                if($data) {
                                    profile.roles = $data;
                                }
                            },
                            true,
                            undefined,
                            'falso'
                        )
                ]);
        }],
        bindings: {
        	definition: '=?',
            roles: '=?'
        }
    })